<template>
  <q-page padding>
    <h5>Categoria</h5>
    <q-form
      @submit.prevent="handleSubmit"
      class=" q-gutter-md"
    >
      <q-input
        label="Nome categoria"
        v-model="form.name"
        :rules="[ val => val && val.length > 0 || 'Favor informe um nome valido!']"
      />
      <q-input
        label="Nome descrição"
        v-model="form.description"
        type="textarea"
        :rules="[ val => val && val.length > 0 || 'Favor informe uma descrição valida!']"
      />
      <q-select
        v-model="form.tema_id"
        :options="themes"
        label="Tema"
        option-value="id"
        option-label="name"
        map-options
        emit-value
      />
      <q-btn
        :label=" isUpdated? 'Atualizar': 'Salvar'"
        color="positive"
        type="submit"
      />
      <q-btn
        label="Cancelar"
        color="warning"
        :to="{name:'category'}"
      />

    </q-form>
  </q-page>
</template>
<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import useApi from '../../composables/UseApi'
import useNotify from '../../composables/UseNotify'
import { useRouter, useRoute } from 'vue-router'
import { useQuasar } from 'quasar'

export default defineComponent({
  name: 'PageFormCategoria',

  setup () {
    const router = useRouter()
    const route = useRoute()
    const $q = useQuasar()
    const table = 'Categoria'
    const { post, getById, update, list } = useApi()
    const { notifyError, notifySuccess } = useNotify()
    const isUpdated = computed(() => route.params.id)
    onMounted(async () => {
      if (isUpdated.value) {
        await handleGetCategory(isUpdated.value)
      }
      await handleGetThemes()
    })
    const themes = ref([])

    let category = {}

    const form = ref({
      name: '',
      description: '',
      tema_id: null
    })

    const handleSubmit = async () => {
      try {
        if (isUpdated.value) {
          await update(table, form.value)
          notifySuccess('Categoria atualizada com sucesso!')
        } else {
          await post(table, form.value)
          notifySuccess('Categoria salva com sucesso!')
        }
        router.push({ name: 'category' })
      } catch (error) {
        notifyError(error.message)
      }
    }

    const handleGetCategory = async (id) => {
      try {
        $q.loading.show()
        category = await getById(table, route.params.id)
        form.value = category
        $q.loading.hide()
      } catch (error) {
        notifyError(error.message)
      }
    }

    const handleGetThemes = async () => {
      try {
        $q.loading.show()
        themes.value = await list('Tema')
        console.log(themes)
        $q.loading.hide()
        return themes
      } catch (error) {
        notifyError(error.message)
      }
    }

    return { form, handleSubmit, isUpdated, themes }
  }
})
</script>
